@import '~bootstrap/scss/bootstrap';
@import '~/node_modules/choices.js/public/assets/styles/choices.min.css';

// General
body {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

ul {
  padding-inline-start: 0;
}

a {
  color: #498ae3;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
}

a:active {
}

h1 {
  font-size: 3.2rem;
  font-weight: 700;
  text-align: center;
  color: #1D3C6A;
}

@media (max-width: 385px) {
  h1 {
    font-size: 2.2rem;
  }
}

h2 {
  font-size: 3rem;
}

h3 {
  color: #d40029;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.page-body-content h3 {
  text-align: left !important;
}

h4 {
  color: #d40029;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

footer h4 {
  color: #498ae3;
}

.body-content ul {
  list-style-type: disc !important;
}

@media (max-width: 990px) {
  .container {
    max-width: 856px !important;
  }
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.dropdown-header {
  color: #1d3c6a;
  font-size: 1.4rem;
  text-align: left;
}

.lost-debit-col {
  h2.dropdown-header {
    padding: 0.5rem 0 !important;
  }

  @media (max-width: 991px) {
    h2.dropdown-header, .debit-dropdown-item {
      padding: 0.5rem 1.5rem !important;
    }
  }
}
.dropdown-item a {
  color: #498ae3;
  padding-left: none !important;
}

.breadcrumb {
  background-color: white;
}

iframe {
  border: none;
}

// navigation

.navbar-brand {
  flex: 1;

  img {
    width: 200px;
    @include media-breakpoint-down(xs) {
      width: 110px;
      margin-right: 1px;
    }
  }
}

a.navbar-main {
  color: #1d3c6a !important;
  text-decoration: none;

  @include media-breakpoint-up(lg) {
    height: 6.25rem;
    border-right: 1px solid #cdcdcd;
    padding-top: 1.6rem;
  }
}

a.navbar-main:hover {
  cursor: pointer;
  background-color: #f3f3f3;
  text-decoration: none;
}

a.navbar-main:active {
  font-weight: normal;
  color: #1d3c6a !important;
  background-color: #f3f3f3 !important;
}

.login-btn {
  white-space: nowrap;
  margin-right: 1em;
  &:hover {
    background-color: #c41616;
    border-color: #c41616;
  }

  @media (max-width: 350px) {
    .fa-lock {
      display: none;
    }
    margin-right: 0;
  }

  @include media-breakpoint-down(sm) {
    font-size: 0.8rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.1rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.4rem;
  }
}

.signup-link {
  font-size: .6rem;
  letter-spacing: .03rem;
  display: table;
  line-height: .5rem;
  padding-left: 24px;
  padding-bottom: 7px;
  text-transform: uppercase;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.login-nav {
  // Vertically center the login button
  display: flex;
  justify-content: center;
  align-items: center;
  // Let the nav item stretch height to match rest of nav,
  // so the dropdown aligns with the bottom of the nav
  align-self: stretch;
  margin-right: .5rem;
  
  .btn {
    display: block;
  }
  .form-group {
    text-align: left !important;
  }
  @include media-breakpoint-up(lg) {
    margin-left: 2rem;
    margin-right: 0;
    order: 3;
  }

  @include media-breakpoint-down(sm) {
    position: static;
    margin-right: 0;
    .dropdown-menu {
      width: 100%;
    }
  }

  @include media-breakpoint-up(sm) {
    .dropdown-menu {
      min-width: 340px;
    }
  }
}

.form-check-label {
  line-height: 1.5;
}

.fa-lock {
  color: #e88a8a;
  font-size: 1em;
}

.fa-chevron-down {
  font-size: 0.8em;
}

nav {
  background-color: white;
}

.navbar {
  font-size: 1.6875rem;
  padding: 0;
}

.main-nav-section {
  max-width: 1420px;
  width: 100%;
}

a.nav-link-special {
  color:#8bbef4 !important;
}
.navbar-main-links li {
  text-align: center;

  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }
}

.navbar-expand-lg .navbar-nav .nav-link {
  @include media-breakpoint-up(lg) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media (max-width: 1341px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.navbar-main-links > li:first-child {
  @include media-breakpoint-up(lg) {
    border-left: 1px solid #cdcdcd;
  }
}

.two-lines-nav {
  @include media-breakpoint-up(lg) {
    line-height: 2rem !important;
    max-width: 255px;
  }
}

.products-section {
  padding-top: 1.1rem !important;
}

// Dropdown animation
.dropdown .dropdown-menu {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.dropdown.show .dropdown-menu {
  /* For Bootstrap 4, use .dropdown.show instead of .dropdown.open */
  max-height: 1400px;
  opacity: 1;
}

// Dropdown on table-hover
.navbar-default .navbar-nav > li.dropdown:hover > a,
.navbar-default .navbar-nav > li.dropdown:hover > a:hover,
.navbar-default .navbar-nav > li.dropdown:hover > a:focus {
  background-color: rgb(231, 231, 231);
  color: rgb(85, 85, 85);
}
li.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-menu {
  border-top: 10px solid #498ae3;
  border-radius: 0;
  margin-top: 0;
  font-size: .95rem !important;
  line-height: 1.9rem;
  // min-width: 240px;
}

.banking-links {
  text-align: left !important;
  padding-left: 1.5rem;
  list-style: none;
}

.login-dropdown {
  padding: 0;
  max-width: 400px;
  text-align: left;
  position: absolute;
  right: 81px;
  .dropdown-item {
    line-height: normal;
  }
}

.multi-column-dropdown {
  list-style: none;
  margin-bottom: 1rem;
  // margin-left: 1rem;
}

.columns-3 {
  // min-width: 720px;
}

.support-nav .dropdown-menu {
  // left: -300px;
  // min-width: 800px;
}

.dropdown-item {
  text-indent: 0;
  text-align: left;
}

.debit-dropdown-item p {
  line-height: 1.9rem !important;
  font-size: .95rem !important;
}

.dropdown-menu a {
  color: #498ae3;
  border: none;
  font-size: .95rem;
  line-height: 1.4rem;
}

.dropdown-menu a:hover {
  cursor: pointer;
  text-decoration: none;
  color: #1d3c6a;
}

.dropdown-menu .dropdown-item:hover, .dropdown-item:focus {
  background: none !important;
}

@media (max-width: 767px) {
  .dropdown-menu.multi-column {
    min-width: 240px !important;
    overflow-x: hidden;
  }
}

@media (max-width: 991px) {
  .contact-nav {
    .dropdown-menu {
      margin-bottom: 1em;
    }
  }
  .dropdown-menu {
    text-align: center !important;
  }
}

a:active {
  color: #1d3c6a;
  cursor: pointer;
}

.nav-col {
  // width: 190px;
}

@media (min-width: 991px) {
  .col-no-heading {
    margin-top: 2.6rem;
  }
}

.secondary-nav {
  background-color: #02275d;
  border-bottom: 1px solid #cccccc;
  padding: 0;
  font-size: 0.8rem;
  display: flex row;
  justify-content: center;
  flex-wrap: wrap;
}

.nav { margin-bottom: 0; }
.dropdown { position: static; }
.dropdown-menu { width: 100%; text-align: center; }
.dropdown-menu>li { display: inline-block; }

.products-dropdown .row, .support-dropdown .row, .contact-dropdown .row {
  justify-content: center;
}

.quick-links {
  background-color: #dcedff;
  // padding: 2px 0 !important;
  margin: 8px 10px;
  // max-width: 250px;

  .dropdown-item {
    padding: 8px 10px;
  }
}
// jumbotron homepage

// .jumbotron {
//   background: url('../images/home-jumbotron.jpg');
//   background-color: #002a5c;
//   background-size: cover;
//   background-repeat: no-repeat;
//   padding: 0;
//   margin: 0;

//   background-position: 0 0;

//   @media (max-width: 1600px) {
//     background-position: 0 0;
//   }
//   @media (max-width: 1200px) {
//     background-position: -200 0;
//   }
//   @media (max-width: 833px) {
//     background-position: 0 0;
//   }
//   @media (max-width: 585px) {
//     background-position: -50 0;
//   }
//   @media (max-width: 500px) {
//     background-position: -250 0;
//   }
// }

// .homepage {
//   @media (min-width: 1600px) {
//     // height: 100vh;
//     min-height: calc(100vh - 135px);
//     background-position: 50% 0;
//   }
// }

.fullscreen-container {
  display: flex;
  
  @media (max-width: 700px) {
    display: block;
  }
  
}

.homepage {
  background-color: #002a5c;
}

.campaign-section {
  background: url('../images/home2-jumbotron.jpg') no-repeat center center;
  background-size: cover;
  width: 50%;
  display: flex;

  @media (max-width: 700px) {
    width: 100%;
    padding: 10% 0;
  }
  

  h2 {
    font-size: 1.5rem;
    color: white;
    font-weight: 400;

    @media (max-width: 410px) {
      font-size: 1.3rem;
      text-align: center;
    }
  }
}

 
  /* RETINA */
  @media 
  (-webkit-min-device-pixel-ratio: 2),(-moz-device-pixel-ratio: 2), (min-resolution: 192dppx) { 

  .campaign-section {
  background: url('../images/home2-jumbotron@2x.jpg') no-repeat center center;
  background-size: cover;
  }
}

.campaign-section-content {  
  margin: auto;
  justify-content: center;
}

.campaign-text { 
  text-align: left;
  color: white;
  font-weight: 700;
    
  h1 {
    text-align: left;
    font-size: 4.8rem;
    color: white;
    line-height: 5rem;

    @media (max-width: 1200px) {
      font-size: 3rem;
    }

    @media (max-width: 833px) {
      font-size: 2.25rem;
      line-height: 3rem;
      text-align: left;
    }

    @media (max-width: 410px) {
      font-size: 1.75rem;
      line-height: 3rem;
      text-align: center;
    }
  }

  .red {
    color: #d40029;
    font-size: 7rem;

    @media (max-width: 991px) {
      font-size: 6rem;
    }

    @media (max-width: 833px) {
      font-size: 5rem;
      text-align: left;
    }

    @media (max-width: 410px) {
      font-size: 4rem;
      text-align: center;
    }
  }

  @media (max-width: 1300px) {
    // width: 75%;
  }
}

.carousel-section {
  width: 50%;
  // display: flex;
  overflow: hidden;

  @media (max-width: 700px) {
    width: 100%;
  }
}

.carousel {
  // height: calc(100vh - 183px);
  // overflow: hidden;
  img {
    cursor: pointer;
  }
}

.help-box {
  .header-logo {
    text-align: center;
    margin: 10% 5% 0 0;

    img {
      @media (max-width: 1300px) {
        max-width: 250px;
      }
    }

    @media (max-width: 833px) {
      display: none;
      visibility: hidden;
      margin: 0;
    }
  }
}

.header-logo {

  img {
    @media (max-width: 1300px) {
      max-width: 250px;
    }
  }

  @media (max-width: 833px) {
    display: none;
    visibility: hidden;
    margin: 0;
  }
}

.jumbotron .row {
  margin-right: 0 !important;
}

.jumbotron .custom-select, .nav-search .custom-select {
  background-color: #d30029;
  border: none;
  width: auto;
  border-radius: 0;
}

.dropdown-notice p {
  font-size: 12px;
  line-height: 1.2em;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}
.dropdown-notice-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.choices__input {
  color: #1d3c6a;
  font-size: 16px;
}

.choices {
  background-color: #d30029;
  border: none;
  width: auto;
  border-radius: 0;
  max-width: 390px;
  text-align: left;
  padding: 1px 0;

  @media (max-width: 425px) {
    max-width: 320px;
    margin: 0 auto;
  }

  @media (max-width: 330px) {
    max-width: 270px;
    margin: 0 auto;
  }
}

.choices__inner {
  background-color: #d30029;
  color: white;
  border: none;
  font-size: 1.2rem;
  color: white;
}

.choices__list--dropdown {
  opacity: 0.95;
  color: #1d3c6a;
}

.choices__placeholder {
  opacity: 1;
  margin-left: 0.5em;
}

.choices__item {
  font-size: 1.2rem;
}

// Search Modal Window
.search-bar .btn {
  // margin-left: 1em;
 :hover {
   cursor: pointer;
 }
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

.fa-search {
  color: #498ae3;
  font-size: 1.6rem;
}

.navbar-btn-small {
  @media (min-width: 1260px) {
    display: none !important;
    visibility: hidden;
  }
}

.fdic-digital {
  max-width: 400px;
  width: 100%;
  margin-top: 2px;

  @media (max-width: 706px) {
    max-width: 350px;
  }
  @media (max-width: 610px) {
    max-width: 400px;
    width: 100%;
    margin-top: 4px;
  }
  @media (max-width: 410px) {
    max-width: 400px;
    width: 100%;
    margin-top: 0;
  }
}

.search-bar {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;

  .search-box {
    width: 300px;
  }

  .fa-search {
    font-size: 1.4rem;
  }

  .search {
    color: #498ae3;
    @media (max-width: 400px) {
      display: none;
    }
  }

  .navbar-btn-large {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.search-bar {
  @media (max-width: 1260px) {
    display: none;
    visibility: hidden;
  }
}
.modal-dialog .close {
  color: white !important;
}

.modal-body h1 {
  color:white;
}

.modal {
  padding-right: 0 !important;
}

.modal-header {
  border-bottom: none;
}

.modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.modal-backdrop {
background-color:#002a5c;
  opacity:.9 !important;
    // border: 10px solid rgba(136, 136, 136, .4);
}

.modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
  background: none;
  box-shadow: none;
}

.modal-body {
  text-align: center;
}

.modal-body form {
  margin: 0 auto;
  float: none;
  width: 300px;
}

@media (min-width: 576px) {
.modal-dialog {
    max-width: none;
  }
}

.modal-content .close {
  opacity: 1;
  font-size: 30px;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border: none;
}

.search-bar form {
  margin-bottom: 0;
  margin-block-end: 0;
}

.navbar-brand {
  @media (max-width: 576px) {
    margin-right: 0 !important;
  }
}

.navbar-toggler {
  padding: 0 !important;
  border: none !important;
}

.navbar-light .navbar-toggler {
  color: #002a5c !important;

}

// Dropdown arrow
.choices[data-type*='select-one']:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f078';
  color: white;
  height: auto;
  width: auto;
  border: 0;
  margin-top: -0.75rem;
}

// Alert Message
@media only screen and (max-width: 660px) {
  .alert-message {
    h3 {font-size: 1rem;}
  }
  .alert-text {
    font-size: .8rem !important;
    line-height: 1rem;
  }
}

p.alert-text {
  margin-bottom:0;
  padding-bottom:0;
  padding-right: 30px;
  font-size: 1em;
}

.digital-banking-support-section {
  background-color: #cce5ff;
  display: flex;
  justify-content: center;
}

.digital-support-container {
  max-width:1500px;
  width: 100%;
  padding: 1em 1em;
  // width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  column-gap: 1.5rem;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    row-gap: 1rem;
    text-align: center;
  }
}

.digital-btns {
  width: 100%;
  max-width: 300px;
  justify-content: end;
  display: flex;
  column-gap: 20px;
  @media only screen and (max-width: 1000px) {
    flex-direction: row;
    row-gap: 20px;
    column-gap: 20px;
    padding-bottom: 1em;
  }
}
.digital-banking-support-text {
  width: 100%;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6rem;

  @media only screen and (max-width: 1000px) {
    font-size: .8rem;
    line-height: 1.2rem;
  }
}

.user-guides-btn {
  background-color: #dc3545;
  padding: 10px 20px;
  color: white;
  border-radius: 0.25rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.2em;
  max-width: 150px;
  width: 100%;
  text-align: center;

  @media only screen and (max-width: 1000px) {
    padding: 10px 4px;
    max-width: 220px;
    font-size: .8em;
  }
}

.user-guides-btn:hover {
  background-color: #c41616;
}
a.user-guides-btn {
  color: white;
}

a.user-guides-btn:hover {
  color: white;
}

// jumbotron interior pages
.checking-accounts .jumbotron {
  background: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url('../images/checking-jumbo2.jpg');
  background-size: cover;
  background-position: center center;
  text-align: center;
  padding: 2% 0;
  margin-bottom: 0;

  @media (max-width: 870px) {
    background-position: center left;
  }
}


.support .jumbotron {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url('../images/contact-us-jumbo2.jpg');
  background-size: cover;
  background-position: center center;
  text-align: center;
  padding: 2% 0;
  margin-bottom: 0;
}

.savings-accounts .jumbotron {
  // background: url('../images/savings-jumbo.jpg');
  background-color: #4f684b;
  background:  linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url('../images/jumbo-savings-v3.jpg');
  background-size: cover;
  background-position: center center;
  text-align: center;
  padding: 2% 0;
  margin-bottom: 0;
}

.loans .jumbotron {
  // background: url('../images/loans-jumbo.jpg');

  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url('../images/loans-jumbo-v3.jpg');
  background-size: cover;
  background-position: center center;
  text-align: center;
  padding: 2% 0;
  margin-bottom: 0;
}

.covid-page .jumbotron {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url('../images/jumbo-covid.jpg');
  background-size: cover;
  text-align: center;
  padding: 5% 0;
  margin-bottom: 0;

}

.services .jumbotron {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url('../images/jumbo-education-v4.jpg');
  background-size: cover;
  background-position: center center;
  text-align: center;
  padding: 2% 0;
  margin-bottom: 0;
}

.resources .jumbotron {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url('../images/resources-jumbo2.jpg');
  background-size: cover;
  background-position: center center;
  text-align: center;
  padding: 2% 0;
  margin-bottom: 0;

  @media (max-width: 870px) {
    background-position: center right;
  }

  @media (max-width: 465px) {
    background-position: 500px center;
  }
}

.financial-tools .jumbotron {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url('../images/financial-jumbo.jpg');
  background-size: cover;
  text-align: center;
  padding: 2% 0;
  margin-bottom: 0;
}

.education .jumbotron {
  // background: url('../images/education-jumbo2.jpg');
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url('../images/jumbo-services2.jpg');
  background-size: cover;
  background-position: center center;
  text-align: center;
  padding: 2% 0;
  margin-bottom: 0; 
}

.about-page .jumbotron {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url('../images/jumbo-about-v2.jpg');
  background-size: cover;
  background-position: center center;
  text-align: center;
  padding: 5% 0;
  margin-bottom: 0;

  @media only screen and (max-width: 660px) {

    background-position: center center;
  }
}


.careers-page .jumbotron {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url('../images/jumbo-careers.jpg');
  background-size: cover;
  text-align: center;
  padding: 5% 0;
  margin-bottom: 0;
}


.basic-page .jumbotron {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9)), url('../images/jumbotron_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #d7e1b0;
  text-align: center;
  padding: 2% 0;
  margin-bottom: 0;
  h1 {
    font-weight: 700;
  }
  @media only screen and (max-width: 660px) {
    h1 {text-shadow: 2px 1px 2px rgba(255,255,255, 0.4);}
    background-position: 0, 0;
  }
}

.checking-accounts h1,
.support h1,
.savings-accounts h1,
.loans h1,
.services h1,
.financial-tools h1,
.education h1,
.resources h1,
.about-page h1,
.careers-page h1,
.basic-page h1 {
  font-weight: 500;
  color: white;
}

.checking-accounts h2,
.support h2,
.savings-accounts h2,
.loans h2,
.services h2,
.financial-tools h2,
.education h2,
.resources h2,
.about-page h2,
.careers-page h2,
.basic-page h2 {
  font-size: 1.8rem;
  color: white;

  @media (max-width: 385px) {
  font-size: 1.4rem;
  line-height: 2rem;
  }
}

.basic-page h1,
.basic-page h2 {
  color:#1d3c6a;
}

.checking-desc {
  margin: -3% 0 4% 0;
}

.about-intro {
  font-size: 1.25rem;
  line-height: 2.5rem;

  @media (max-width: 600px) {
    font-size: 1rem;
    line-height: 1.8rem;
  }
} 

.interior-pg-img {
  text-align: center;
}


.interior-pg-details {
  // background-color: #f1f1f1;
  // padding: 2rem;
}

// Accounts

#accounts {
  // background-color: #023a7a;
  background: rgb(0,17,75);
  background: linear-gradient(0deg, rgba(0,17,75,1) 0%, rgba(15,81,140,1) 100%);
}

.accounts {
  margin: 0 auto;
  padding: 0 0;

  h2 {
    font-size: 1.5rem !important;
  }
}

.account-type {
  text-align: center;
  color: white;
  padding: 40px;
  font-size: 1.1rem;
  font-weight: 600;
}

.accounts-icon {
  height: 160px;
}

.accounts a:link {
  border-bottom: solid 1px;
  font-weight: 600;
}
.accounts a:hover {
  color: white;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 30px;
  height: 30px;
  a:hover {
    cursor: pointer;
  }
}

// community

.community {
  margin: 0 auto;
  padding: 2rem 0;
}

.community-gallery {
  margin: 0 auto;
  text-align: center;
}

.community-gallery ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-inline-start: 0;
  justify-content: center;
}

.community-gallery ul li {
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.community-gallery ul li img {
  border-radius: 5px;
  width: auto;


  @include media-breakpoint-up(xs) {
    width: 100px;
  }

  @include media-breakpoint-up(md) {
    width: 155px;
  }

  @include media-breakpoint-up(lg) {
    border-radius: 5px;
    width: 225px;
  }

  @media only screen and (min-width: 976px) and (max-width: 1184px) {
    width: 185px;  
  }
}

.community-gallery-page ul {
  display: flex;
  flex-wrap: wrap;
}

.community-gallery-page ul li {
  list-style: none;
  padding-right: 20px;
  padding-bottom: 20px;
}

.community-gallery-page ul li img {
  @include media-breakpoint-up(xs) {
    width: 90px;
  }

  @include media-breakpoint-up(md) {
    width: 155px;
  }
}

.view-more-link {
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
  padding-right: 1rem;
  margin-bottom: 1em;
}

.view-more-link a:link {
  color: #d93c3c;
  border-bottom: 1px solid;
}

.view-more-link a:hover {
  color: #8a0202;
  cursor: pointer;
}

.view-more-link a:visited {
  color: #d93c3c;
}

.community .social-media .btn-primary a {
  color:white;
}

.employee-spotlight {
  background-color: #f3f3f3;
  text-align: center;
  // border: 1px solid #cdcdcd;
  // background-color: white;
  padding: 15px 15px 5px 15px;
}

.employee-spotlight h3{
  text-align: center;
}

.employee-spotlight-name {
  color: #498ae3;
  font-size: 1.75rem;
  font-weight: 800;
}

.community h4 {
 display: inline;
 @media only screen and (max-width: 438px) {
  display: block;
  padding-top: 1rem;
  }
}

.fa-users {
  color: #fd6d6d;
  font-size: 1.7rem;
}
.social-section {
background-color: #f3f3f3;
padding: 24px 0 33px 0;
}

// Meet our Staff Page

.meet-our-staff {
  h2.branch-heading {
    text-transform: uppercase !important;
    color:#c41616 !important;
    text-align: center !important;
    font-weight: 700;
    margin-bottom: 2rem;
  }
}

.meet-our-staff ul {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  justify-content: center;
  padding-left: 0 !important;
  li {
    list-style: none;
    // background-color: #e9f4ff;
    padding: 1rem;
    max-width: 250px;
  }
  img {
    height: 200px;
    width: 200px;
    border-radius: 2%;
    // border: 10px solid #ffffff;
  }
  h3 {
    text-transform: none;
    font-size: 1.6rem;
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: .25rem !important;
  }
  h4 {
    text-transform: none;
    font-weight: 600;
    font-size: .9rem;
    color:#004497;
  }
  .nmls-id {
    font-size: .9rem;
    font-weight: 600;
  }
}

.light-blue-bg {
  background-color: #e5f1ff;
  padding: 4rem 0;
  margin-bottom: 4rem;
}
// Slider

.slider-btn .btn-primary {
  position: absolute;
  top: 65%;
  left: 46%;
  background-color: #d93c3c;
  border-color: #d93c3c;
}

.slider-btn .btn-primary:hover {
  background-color: #c41616;
  border-color: #c41616;
  cursor: pointer;
}

// alert

.alert-message {
  h3 {
  font-size: 1.4rem;
  }
  p.alert-text {
    font-size: .8rem;
    @media only screen and (max-width: 660px) {
      font-size: .7rem;
      text-align: left;
    }
  }
}

.alert-dismissible {
  @media only screen and (max-width: 660px) {
    padding-right: 0;
  }
}

.alert-heading {
  color: #c41616;
}

.alert {
  margin-bottom: 0;
  text-align: center;
}


@media only screen and (max-width: 660px) {
  .alert-message {
    h3 {font-size: 1rem;}
    p {
      font-size: .8rem !important;
    }
  }
}

.fa-mobile-alt {
  font-size: 6rem;
  color: #d1e4fc;
}

// Inner Pages
.checking-accounts h3,
.savings-accounts h3,
.loans h3,
.services h3,
.support h3,
.education h3,
.resources h3 {
  text-align: center;
  margin-bottom: 1em;
}

.checking-accounts table,
.savings-accounts table,
.loans table,
.services table,
.support table,
.education table,
.resources table {
  width: 100%;
}

.checking-accounts .table h2,
.savings-accounts .table h2,
.loans .table h2,
.services .table h2,
.support .table h2,
.education .table h2,
.resources .table h2 {
  color: #2c2c2c !important;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: left;
}

.checking-accounts table td,
.savings-accounts table td,
.loans table td,
.services table td,
.support table td,
.education table td,
.resources table td {
  border-right: 2px solid white;
}

.interior-page-content {
  h1 {
    color: #d93c3c;
    font-size: 1.75rem;
    font-weight: 400;
  }

  h2 {
    color: #2c2c2c !important;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: left;
  }

  ul {
    list-style-type: disc;
  }
}

.advantages-list, .tax-advantages {
  display: flex;
  flex-direction: column;
  flex: wrap;
  align-content: space-between;
  gap: 20px;
  padding-left: 0 !important;
  li {
    list-style: none;
    padding-left: none;
    padding: 15px;
    background-color: #bedbff;
    border-top: 20px solid #498ae3;
    color: #00306b;
    font-size: 1.1em;
    font-family: proxima-nova, sans-serif;

    @include media-breakpoint-up(lg) {
      max-width: 220px;
      width: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
  }
}

.tax-advantages {
  li {
    border-top: 20px solid #34b068;
    background-color: #c3e6d1;
    color: #2c2c2c;
  }
  padding-bottom: 3em;
}

.advantages-main {
  font-size: 1.2em;
}

.adv-of-hsa {
  padding-bottom: 2em;
}

.hsa-intro {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 1em;
  padding-top: 1em;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    padding-bottom: 3em;
  }
}

.hsa-intro-img {
  margin-right: 20px;
  img {
    width: 100%;
    max-width: 450px;

    @include media-breakpoint-up(md) {
      width: 450px;
    }
  }
}

.table td,
.table th {
  border-top: none !important;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #dfedff;
}

.fa-angle-right {
  color: #c41616;
  padding-right: 0.25rem;
}

.infobox {

  .bg-primary {
    background-color: #f2f2f2 !important;
  }
}

#estatements {
  border: 1px solid #cccccc;
  padding: 20px 30px;
  margin-top: 2rem;
  // background-color: #f2f2f2 !important;
  p {
  font-size: .8rem;
  }
  h5 {
    font-weight: 700;
    font-size: 2rem;
    color: #02275d;
  }
}

.search-results {
  border-top: 1px solid #cdcdcd;

  h1 {
    margin-top: 3rem;
  }

  .interior-page-content {
    margin-bottom: 2rem;
  }
}

.list-group {
  margin-top: 3rem;
}
// events

.events-page {
  h2 {
    text-align: center;
  }
  ul {
    text-align: center;
  }
}

.events-page ul li {
  list-style: none;
  margin-bottom: 1em;
  text-align: left;
}

// .interior-page-content {
//   ul {
//     list-style: none !important;
//     padding-left: 0;
//     text-align: center;
//   }
//   li {
//     font-size: 1.2rem;
//     font-weight: 600;

//     &:before {
//       content: '';
//       display: inline-block;
//       height: .8em;
//       width: .8em;
//       background-image: url('../images/check-circle-fill.svg');
//       background-size: contain;
//       background-repeat: no-repeat;
//       margin-right:0.5em;
//     }
//     margin-bottom: 1rem;
//   }
//   svg {
//     fill: #498ae3;
//   }
// }

.interior-page-content img {
  vertical-align:baseline;
}

.careers-page .interior-pg-details {
  ul {
    list-style: none !important;
    padding-left: 0;
  }
  li {
    &:before {
      content: '';
      display: inline-block;
      height: 1em;
      width: 1em;
      background-image: url('../images/check-circle-fill.svg');
      background-size: contain;
      background-repeat: no-repeat;
      margin-right:0.5em;
    }
    margin-bottom: 1rem;
  }
  svg {
    fill: #498ae3;
  }
}

// footer

footer {
  background: url('../images/footer-bg2.png') top center no-repeat;
  background-color: #002a5c;
  font-size: .9rem;

    .btn-facebook {
      background-color: #498ae3;
    }
    .btn-facebook:hover {
      background-color: #498ae3;
    }
}

footer p {
  color: #498ae3;
}

footer ul {
  padding-inline-start: 0;
  line-height: 1.5rem;
}

.footer-icons {
  padding: 3rem 0;
}

footer li {
  list-style: none;
}

.footer-nav a {
  color: white;
  text-decoration: none;
}

.footer-nav a:hover {
  cursor: pointer;
  color: #498ae3;
  text-decoration: none;
}

.footer-nav a:active {
  cursor: pointer;
  color: #498ae3;
}

.footer-credits a {
  border-bottom: 1px solid;
}

.footer-credits a:hover {
  color: white;
}

// Products nav
.products-nav {
  font-weight: 500;
  background-color: #eff2f5;

  ul {
    list-style: none;

    li {
      flex-basis: 100%;
      margin-top: 0.5rem;

      @include media-breakpoint-up(md) {
        flex-basis: 50%;
      }

      @include media-breakpoint-up(lg) {
        flex-basis: 33.333%;
        margin-top: 1rem;
      }
    }
  }

a.apply-btn {
  background-color:#004497;
  color:white !important;   
  border-radius: 0.4rem;
  border-color:#004497;
  font-size: .75rem;
  padding: 4px 7px;
  cursor: pointer;

  @include media-breakpoint-down(sm) {
    font-size: .5rem;
  }
}
  
a.apply-btn:hover, a.apply-btn:active {
  background-color:#00306b;
  cursor: pointer;
  color:white !important;    
}

a.apply-btn:visited {
  background-color:#004497;
  color: #c6e0ff !important;
}
  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
}

.products-nav-only-4 {
  @include media-breakpoint-up(lg) {
    ul {
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;

      li {
        flex-basis: 50%;
      }
    }
  }
}

.products-nav a:link {
  border-bottom: 1px solid;
  color: #498ae3;
  cursor: pointer;
}

.products-nav a:visited {
  color: #498ae3 !important;
}

.products-nav a:hover {
  color: #1d3c6a !important;
  cursor: pointer;
}

.products-nav a:active {
  color: #1d3c6a !important;
  cursor: pointer;
}

.btn-tiny {
  font-size: .75rem;
  padding: 2px 5px;
}

// btn-primary:hover, .btn-primary:active, .btn-primary:visited {

// }

.open-acct-link {
  display: inline;
  position: relative;
  margin-left: 5px;
  bottom: 2px;
}

a.open-acct-btn {
  color: #fff !important;
  border-bottom: none;
}

.interior-nav li {
  display: inline;
}

.interior-nav {
}

// Contact page
.fa-map-marker-alt {
  font-size: 2.5rem;
  color: #d93c3c;
}

// Calendar

.community h3 {
  text-align: center;
}

#calendar h2 {
  font-weight: 600;
  color: #002a5c;
  font-size: 1.4rem;
  text-align: center;
}

#calendar {
  margin: 0 auto;
}

.calendar {
  margin-right: 0;
  margin: 0 auto;
}

.calendar-day {
  color: #8e8e8e;
}

.calendar-day-has-events {
  color: #eb5e5e;
}

.calendar-event .time {
  font-weight: bold;
}

.calendar-nav {
  text-align: center;
}
.calendar-nav li {
  display: inline;
  list-style: none;
  padding-left: 0.25rem;
}

.calendar thead {
  padding-bottom: 1rem;
}

.calendar thead tr th {
  padding-right: 1rem;
}

.calendar tbody td {
  padding-bottom: 1rem;
}

.calendar-event-wrapper button {
  background: none;
  border: none;
  padding: 0 0;
}
.calendar-event-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.calendar-event-list li:last-child {
}

.calendar-event {
  padding-bottom: 0.5rem;
}

.interior-page-content {
  ul,
  ol {
    padding-left: 1.5rem;
  }

  table {
    @extend .table;
    @extend .table-striped;
    @extend .table-hover;
  }
}

@media (min-width: 992px) {
  #calendar h2 {
    float: left;
  }
  .calendar-nav {
    text-align: left;
  }
  .community h3 {
    text-align: left;
  }
  .employee-spotlight h3 {
    text-align: center;
  }
  .view-more-link {
    text-align: right;
  }
  .community-gallery ul {
    justify-content: left;
  }
  .community-gallery ul li img {

  }
}
.events-page {
  margin: 0 auto;
  .vevent {
    background-color: #e3f1ff;
    padding: 15px;
    font-weight: bold;
    text-align: left;
    width: 170px;
  }

  .calendar {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    gap: 10px;
    margin: 0 auto;
  }

  .day { text-transform: none;
    font-size: .7em;}
}
// Social Media Section
a.btn-social,
.btn-social
{
    border-radius: 50%;
    color: #ffffff !important;
    display: inline-block;
    height: 44px;
    line-height: 44px !important;
    margin: 8px 4px;
    text-align: center;
    text-decoration: none;
    transition: background-color .3s;
    webkit-transition: background-color .3s;
    width: 44px;
}

.btn-social .fab {
  line-height: 44px;
}

.btn-social .fa,.btn-social i
{
    backface-visibility: hidden;
    moz-backface-visibility: hidden;
    ms-transform: scale(1);
    o-transform: scale(1);
    transform: scale(1);
    transition: all .25s;
    webkit-backface-visibility: hidden;
    webkit-transform: scale(1);
    webkit-transition: all .25s;
}
.btn-social:hover,.btn-social:focus
{
    color: #fff;
    outline: none;
    text-decoration: none;
}
.btn-social:hover .fa,.btn-social:focus .fa,.btn-social:hover i,.btn-social:focus i
{
    ms-transform: scale(1.3);
    o-transform: scale(1.3);
    transform: scale(1.3);
    webkit-transform: scale(1.3);
}
.btn-social.btn-xs
{
    font-size: 9px;
    height: 24px;
    line-height: 13px;
    margin: 6px 2px;
    width: 24px;
}
.btn-social.btn-sm
{
    font-size: 13px;
    height: 36px;
    line-height: 18px;
    margin: 6px 2px;
    width: 36px;
}
.btn-social.btn-lg
{
    font-size: 22px;
    height: 72px;
    line-height: 40px;
    margin: 10px 6px;
    width: 72px;
}

.btn-facebook
{
    background-color: #002a5c;
}
.btn-facebook:hover
{
    background-color: #498ae3;
}

.keyword {
  background-color: #cce5ff;
  font-style: normal;
  border-radius: 1px;
}

.account-signup-form {
  background-color: #dfecfd;
  padding: 40px;
  margin-top: 40px;
  border-radius: 20px;

  label {
    font-size: 18px;
    margin-bottom: 3px;
  }

  .choose-account {
    font-size: 18px;
  }

  .form-check-label {
    font-size: 16px;
    line-height: 2em;
  }

  .form-check-input {
    width: 17px;
    height: 17px;
    // padding: 0;
    // margin:0;
    vertical-align: middle;
    position: relative;
    top: -4px;
    *overflow: hidden;
  }

  #form1_submit {
    margin-bottom: 2em;
  }
  .comment-field {
    display: none;
}
}